import React from "react"
import { graphql } from "gatsby"
import Info2column from "../../../components/Info2column"
import QuestionAndAnswer2colmun from "../../../components/QuestionAndAnswer2colmun"
import CommentText from "../../../components/CommentText"
import ColumnGrid from "../../../components/ColumnGrid"
import CommonButton from "../../../components/CommonButton"
import ModalLink from "../../../components/ModalLink"
import LayoutInterview from "../../../components/LayoutInterview"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Seo from "../../../components/Seo"

import IconInfo from "../../../images/icon_info.svg"
import IconPhoto from "../../../images/icon_photo.svg"
import QuestionAndAnswerToggle from "../../../components/QuestionAndAnswerToggle"
import FlagMm from "../../../images/flag_mm.svg"
import FlagJp from "../../../images/flag_jp.svg"
import FaceImg01 from "../../../images/interview/01/face_01.jpg"
import FaceImg02 from "../../../images/interview/01/face_02.jpg"
import css from "../stylesheets/interview.module.scss"
import Img from "gatsby-image"

export default ({ data }) => {
  return (
    <div className={css.InterviewDetail}>
      <Seo
        title={`Teaching Each Other and Working Together | INTERVIEW`}
        lang={`en`}
      />
      <LayoutInterview lang={`en`}>
        <Info2column id={`interview-01`} noBorder={true}>
          <div>
            <Img fluid={data.image1.childImageSharp.fluid} />
          </div>
          <div>
            <p className={css.date}>{`2020.3.25`}</p>
            <h3 className={`mb20`}>Teaching Each Other and Working Together</h3>
            <p>
              Tomoe Yamazaki
              <small>（Age: 23）</small>× Zin Zin Moe
              <small>（Age: 27）</small>
            </p>
            <p>
              Zin Zin Moe, from Myanmar, started working as{" "}
              <AnchorLink
                className={`linkUnderline`}
                to="/en/basic-information/kaigo/#section-2"
              >
                a technical intern trainee
              </AnchorLink>{" "}
              at a group home{" "}
              <ModalLink id={`facility`} className={`linkUnderline`}>
                <span style={{ color: "black" }}>
                  ‘Mankai no Furusato Sakura Hassamu’
                  <img src={IconPhoto} alt="" />
                </span>
                <div>
                  <h3>Workplace</h3>
                  <Img fluid={data.facility1.childImageSharp.fluid} />
                  <Img fluid={data.facility2.childImageSharp.fluid} />
                  <Img fluid={data.facility3.childImageSharp.fluid} />
                  <Img fluid={data.facility4.childImageSharp.fluid} />
                  <Img fluid={data.facility5.childImageSharp.fluid} />
                </div>
              </ModalLink>{" "}
              in Sapporo, Hokkaido. After half a year, Tomoe Yamazaki joined as
              a new recruit. They had a lot of common points such as age and
              working experience so they helped each other while working. “Work
              is fun,” says Zin Zin Moe and Tomoe smiling while being
              interviewed about their job and daily lives.
            </p>
            <h3>PROFILE</h3>
            <table className={`profileTable`}>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>Zin Zin Moe</td>
                </tr>
                <tr>
                  <th>Workplace</th>
                  <td>
                    Care Worker at a group home run by Sakura CS Holdings Co.,
                    Ltd. ‘Mankai no Furusato Sakura Hassamu’
                  </td>
                </tr>
                <tr>
                  <th>Birthplace</th>
                  <td>
                    <img src={FlagMm} alt="Myanmar" className={`flag`} />
                    Bago, Myanmar
                  </td>
                </tr>
                <tr>
                  <th>Age</th>
                  <td>27 (Born in 1993) </td>
                </tr>
                <tr>
                  <th>Education</th>
                  <td>
                    Bachelor of Science in Industrial Chemistry at Dagon
                    University
                  </td>
                </tr>
                <tr>
                  <th>Work Experience</th>
                  <td>Public Health Supervisor in Yangon City</td>
                </tr>
                <tr>
                  <th>Residence Status at Entry to Japan</th>
                  <td>Technical Intern Trainee</td>
                </tr>
                <tr>
                  <th>Arrival Date in Japan</th>
                  <td>January 2019</td>
                </tr>
              </tbody>
            </table>

            <table className={`profileTable`}>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>Tomoe Yamazaki</td>
                </tr>
                <tr>
                  <th>Workplace</th>
                  <td>
                    Care Worker at a group home run by Sakura CS Holdings Co.,
                    Ltd. ‘Mankai no Furusato Sakura Hassamu’
                  </td>
                </tr>
                <tr>
                  <th>Birthplace</th>
                  <td>
                    <img src={FlagJp} alt="Japan" className={`flag`} />
                    Sapporo City, Hokkaido
                  </td>
                </tr>
                <tr>
                  <th>Age</th>
                  <td>23 (Born in 1996)</td>
                </tr>
                <tr>
                  <th>Education</th>
                  <td>
                    Bachelor of Social Welfare in Psychology for Well-Being at
                    Hokusei Gakuen University
                  </td>
                </tr>
                <tr>
                  <th>Work Experience</th>
                  <td>Care Worker at Sakura CS Holdings Co., Ltd.</td>
                </tr>
                <tr>
                  <th>Job Starting Date</th>
                  <td>April 2019</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Info2column>
        <QuestionAndAnswerToggle
          question={`How do you feel about nursing care?`}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image2.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`Zin Zin Moe`}>
              <p>
                I had lived with my grandfather and grandmother since childhood
                and helped them with bathing. So my current job reminds me of my
                old days. My grandmother also had dementia so I feel that the
                communication process with the residents resembles that with
                which I had with her.
                <br />
                Nursing care involves supporting and helping residents to
                overcome their difficulties. Myanmar is a Buddhist country so
                supporting others is said to bring you ‘Kudoku (merit).’ For
                example, your children will take care of you like the way you
                treated your grandparents. It is thought that your virtue
                towards society and other people will circulate. I often feel
                mental stability because nursing care involves practicing virtue
                while working.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Tomoe`}>
              <p>
                My image towards nursing care is supporting the residents close
                by rather than ‘just giving care.’ In our nursing care facility,
                we call the care workers ‘partners.’
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`Zin Zin Moe`}>
              <p>
                Everyday, we discuss how to make each resident happy and help
                them to spend their daily lives easier.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image3.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg02} direction={`right`} name={`Tomoe`}>
              <p>
                We always start by asking the residents what they like or what
                they would like to do. Some residents like{" "}
                <ModalLink id={`modal-1`} style={{ display: `inline-block` }}>
                  <span>
                    Radio Calisthenics
                    <img src={IconInfo} alt="" />
                  </span>
                  <div>
                    <h3>Radio Calisthenics</h3>
                    <p>
                      This exercise aims in developing physical strength and
                      better health. You move your body together with the music
                      played by the piano. It was introduced first in 1928 then
                      broadcasted on NHK radio and became popular all over
                      Japan. It is taught at every school so most Japanese can
                      perform it.
                      <a
                        href={`https://www.youtube.com/watch?v=feSVtC1BSeQ`}
                        rel={`noopener noreferrer`}
                        target={`_blank`}
                      >
                        <br />
                        NHK ‘Radio Calisthenics’
                      </a>
                    </p>
                  </div>
                </ModalLink>{" "}
                , and some like origami (paper folding). Some also love singing.
              </p>
              <p>
                Doing something the residents like is not the only method to
                make them happy. For example, rearranging their rooms is one of
                the ways. We sometimes discuss how to make it easier for them to
                go to the toilet on their own by reducing obstacles in their
                rooms.
              </p>
              <p>
                I'm slowly getting used to my job but still rely on Zin Zin Moe
                because she started her career earlier than I did. She often
                teaches me special techniques for nursing care. I teach her
                Japanese in exchange. So we teach each other things we cannot do
                well. We are both bad at cooking though (laughs).
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image4.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText src={FaceImg01} name={`Zin Zin Moe`}>
                <p>
                  That’s true (laughs). In our nursing care facility, care
                  workers prepare{" "}
                  <ModalLink id={`modal-2`} style={{ display: `inline-block` }}>
                    <span>
                      meals
                      <img src={IconInfo} alt="" />
                    </span>
                    <div>
                      <h3>Meals</h3>
                      <p>
                        At a small group home for the elderly with dementia, the
                        care workers, and sometimes the residents themselves,
                        prepare their own meals with well-balanced nutrition. At
                        the group home where Zin Zin Moe works, the residents
                        and the care workers have their meals together. At
                        bigger nursing care facilities, professional cooks with
                        specialized knowledge prepare the meals.
                      </p>
                    </div>
                  </ModalLink>
                  .
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <div>
              <CommentText src={FaceImg02} direction={`right`} name={`Tomoe`}>
                <p>
                  Zin Zin Moe doesn’t know Japanese cooking and I don’t have
                  much experience in cooking, so we always struggle in front of
                  ingredients (laughs). We tried shredding yesterday.
                </p>
              </CommentText>

              <CommentText src={FaceImg01} name={`Zin Zin Moe`}>
                <p>
                  There are so many ways to chop vegetables in Japanese cooking
                  so I'm practicing each one!
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          question={`What are the distinctive features of your workplace? `}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img
                fluid={data.image5.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img fluid={data.image6.childImageSharp.fluid} />
            </div>

            <div>
              <CommentText src={FaceImg02} direction={`right`} name={`Tomoe`}>
                <p>
                  Our company treasures the relationships with the surrounding
                  community. For example, there is an electronics store across
                  the road and there are always neighbors gathering and
                  chatting. Our residents also join them sometimes. The owner
                  always cares about us and even calls us saying, “I saw someone
                  just leave the house alone. Is everything alright?”
                </p>
              </CommentText>
              <CommentText src={FaceImg01} name={`Zin Zin Moe`}>
                <p>
                  I never imagined such relationships with the local community
                  before coming to Japan. On my first day of work, I went to say
                  hello and introduce myself to the neighbors with the Director
                  of the Facility. She introduced me saying, “She has come from
                  Myanmar as a technical intern trainee. Please take care of
                  her.” I still remember the day clearly because it made my
                  heart very warm.
                </p>
                <p>
                  I greet the neighbors everyday on my way to work and home.
                  They sometimes give me potatoes and grapes which they grow on
                  their vegetable patches. They care about me a lot and offer to
                  help when I need.
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Tomoe`}>
              <p>
                We also plan small day trips for the residents to get in touch
                with the neighbors. Going to{" "}
                <ModalLink id={`modal-3`} style={{ display: `inline-block` }}>
                  <span>
                    ‘dementia café’
                    <img src={IconInfo} alt="" />
                  </span>
                  <div>
                    <h3>Dementia Café</h3>
                    <p>
                      It is an open space for a variety of people, such as
                      people with dementia, their families, local residents and
                      nursing-care-related experts. They casually gather and
                      exchange ideas. It is widely organized all over Japan.
                      When people develop dementia, they tend to have less
                      opportunity to go out and spend most of their time at
                      home. Dementia Café helps the patients and their families
                      to have more chances to contact with other people. The
                      programs provided include teatime, recreation and study
                      group sessions. The number of opportunities held differs
                      depending on the area.
                    </p>
                  </div>
                </ModalLink>{" "}
                is one trip.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`Zin Zin Moe`}>
              <p>
                Seasonal events are also a fun part. At ‘Sakura Festival,’ where
                people enjoyed the cherry blossoms, I danced with my Burmese
                coworkers, wearing national garments.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Tomoe`}>
              <p>
                In summer, we had ‘Tanabata Festival’ to appreciate the stars.
                About 15 local elementary school students, who often attend the
                children’s center nearby, visited our place and performed
                singing, dancing and hand massaging.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>

          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`Zin Zin Moe`}>
              <p>
                Hand massaging was very popular! The residents were happily
                talking and asking their names. They looked very happy with the
                children.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          question={`How are you coping with life in Japanese?`}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image7.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText src={FaceImg01} name={`Zin Zin Moe`}>
                <p>
                  Nursing care in Japan definitely requires verbal
                  communication, so studying Japanese language is necessary.
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Tomoe`}>
              <p>
                She often struggles with grammar rather than understanding the
                meaning of words.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image8.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText src={FaceImg01} name={`Zin Zin Moe`}>
                <p>
                  I'm having difficulties with particles such as ‘ni’ and ‘wo.’
                  My coworkers including Tomoe always help me.
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Tomoe`}>
              <p>
                She studies very hard so she wants to use the new words right
                after she learns. She even writes daily reports in Japanese.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`Zin Zin Moe`}>
              <p>
                In my daily report, I write about good things that happened on
                the day, my opinions, things that need to be improved and my
                goals. My seniors read it and correct my Japanese.
              </p>
              <p>
                I'm still learning Japanese but I love using the language. My
                teacher is Ms. Umezu, one of the residents. She talks very
                politely to me. Every time I help her, she says, “Thank you for
                taking care of me as always.” I admire her a lot.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle question={`How is your life in Japan?`}>
          <QuestionAndAnswer2colmun>
            <div>
              <Img
                fluid={data.image9.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img fluid={data.image10.childImageSharp.fluid} />
            </div>

            <div>
              <CommentText src={FaceImg01} name={`Zin Zin Moe`}>
                <p>
                  It is either ‘warm’ or ‘hot’ in Myanmar, so I was worried to
                  live in a cold country. My suitcase was full of jackets and
                  warm clothes from Myanmar (laughs). When I arrived in Sapporo,
                  I was very surprised to see a world covered with snow. The
                  first things I bought in Japan were{" "}
                  <ModalLink id={`modal-4`} style={{ display: `inline-block` }}>
                    <span>
                      non slip shoes!
                      <img src={IconInfo} alt="" />
                    </span>
                    <div>
                      <h3>Non Slip Shoes on Snowy Roads</h3>
                      <p>
                        In snowy areas, warm, waterproofed and non slip shoes
                        are necessary. Shoes with a rubber sole are highly
                        recommended, because they will prevent you from
                        slipping. They are also called ‘winter boots’ or ‘snow
                        shoes’ in Japan. Please ask staff and they will find you
                        the best one!
                      </p>
                    </div>
                  </ModalLink>
                </p>
                <p>
                  I live with another Burmese technical intern trainee in an
                  apartment which my company prepared for us. I take the subway
                  for 20 minutes and walk another 20 minutes to my workplace. I
                  call my mother on my day off. I also like shopping with my
                  friends and chilling out in the park.
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Tomoe`}>
              <p>
                Our days off often differ. But we went to see the Christmas
                lights with our Facility Director in December!
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img
                fluid={data.image11.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img fluid={data.image12.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`Zin Zin Moe`}>
              <p>
                It was very beautiful because the lights reflected on the snow.{" "}
              </p>
              <p>
                One time, Tomoe gave me a hand-made doll for my birthday. I was
                so happy that I still keep it in the wrapping bag.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle question={`What is your future goal? `}>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`Zin Zin Moe`}>
              <p>
                I would like to obtain a qualification as a Certified Care
                Worker in Japan. There are also nursing homes in Myanmar but the
                number of care workers and skills is not enough. I'm one of the
                first technical intern trainees from Myanmar. I would like to
                become an instructor to teach the techniques of nursing care
                when I go back to Myanmar.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Tomoe`}>
              <p>
                People still think nursing care is a tough job. But we are
                trying to change that image. It is difficult for residents to
                keep in touch with their community once they start living in a
                nursing care facility. I would like to help them to maintain
                their relationships with the community outside of the group
                home.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image13.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`Zin Zin Moe`}>
              <p>
                One of the most important things to work in the nursing care
                field in Japan is studying Japanese as much as possible. You can
                learn the techniques required in nursing care on the job, but
                you need to make an extra effort to be fluent in Japanese. I
                hope I can talk about my experience in Japan for those who are
                planning to work here, as well as the techniques of nursing
                care.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <div className={`mt30`}>
          <Info2column noBorder={true}>
            <div></div>
            <div>
              <p style={{ margin: 0 }}>Workplace</p>
              <h3 className={`mb10`}>
                <ModalLink id={`facility`} className={`linkUnderline`}>
                  <span style={{ color: "black" }}>
                    Mankai no Furusato Sakura Hassamu
                    <img src={IconPhoto} alt="" />
                  </span>
                  <div>
                    <h3>Workplace</h3>
                    <Img fluid={data.facility1.childImageSharp.fluid} />
                    <Img fluid={data.facility2.childImageSharp.fluid} />
                    <Img fluid={data.facility3.childImageSharp.fluid} />
                    <Img fluid={data.facility4.childImageSharp.fluid} />
                    <Img fluid={data.facility5.childImageSharp.fluid} />
                  </div>
                </ModalLink>
              </h3>
              <p className={`mb40`}>
                A ‘group home’ is a nursing care facility for the elderly,
                especially those with dementia. It is a small two-story home
                with a maximum capacity of nine people on each floor. The
                atmosphere is very cozy. Many of the residents spend most of
                their time in the living rooms furnished with kitchens. The home
                organizes many events every season and encourages the residents
                to go out regularly and have connections with the local
                community.
              </p>
              <p>
                <a
                  href={`https://sakura-cs.com`}
                  rel={`noopener noreferrer`}
                  target={`_blank`}
                >
                  https://sakura-cs.com
                </a>
              </p>
              <div className="credit">
                <p>
                  Text by Tami Ono
                  <br />
                  Photo by Mihoko Tsujita
                  <br />
                  Translation by Mihoko Tsujita, Dean Humphrey
                </p>
              </div>

              <div className="mt40">
                <ColumnGrid>
                  <div className={css.commonButton}>
                    <CommonButton to={`/en/interview/3`} align={`center`}>
                      PREV
                    </CommonButton>
                  </div>
                  <div className={css.commonButton}>
                    <CommonButton to={`/en/interview/`} align={`center`}>
                      BACK
                    </CommonButton>
                  </div>
                  <div className={css.commonButton}>
                    <CommonButton to={`/en/interview/2`} align={`center`}>
                      NEXT
                    </CommonButton>
                  </div>
                </ColumnGrid>
              </div>
            </div>
          </Info2column>
        </div>
      </LayoutInterview>
    </div>
  )
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "interview/01/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(relativePath: { eq: "interview/01/02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image3: file(relativePath: { eq: "interview/01/03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image4: file(relativePath: { eq: "interview/01/04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image5: file(relativePath: { eq: "interview/01/05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image6: file(relativePath: { eq: "interview/01/06.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image7: file(relativePath: { eq: "interview/01/07.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image8: file(relativePath: { eq: "interview/01/08.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image9: file(relativePath: { eq: "interview/01/09.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image10: file(relativePath: { eq: "interview/01/10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image11: file(relativePath: { eq: "interview/01/11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image12: file(relativePath: { eq: "interview/01/12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image13: file(relativePath: { eq: "interview/01/13.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    facility1: file(relativePath: { eq: "interview/01/facility_01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility2: file(relativePath: { eq: "interview/01/facility_02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility3: file(relativePath: { eq: "interview/01/facility_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility4: file(relativePath: { eq: "interview/01/facility_04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility5: file(relativePath: { eq: "interview/01/facility_05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
